<template>
  <h3 class="red--text darken-4 text-h3 text-center" v-if="notOrders">
    {{ $t("notOrders") }}
  </h3>
  <section v-else>
    <v-list color="blue-grey lighten-4">
      <ItemOrder
        v-for="order in paginateOrders"
        :key="order.serial"
        :order="order"
      >
        <template #action>
          <v-list-item-action @click="toOrderDetails(order)">
            <v-btn icon>
              <v-icon color="blue lighten-2">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </ItemOrder>
    </v-list>
    <v-pagination :length="length" v-model="page" />
  </section>
</template>

<script>
export default {
  name: "ListOrders",
  props: {
    orders: { type: Array, required: true },
  },
  components: {
    ItemOrder: () => import("@/components/orders/ItemOrder.vue"),
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    toOrderDetails(order) {
      this.$router.push({ name: "OrdersDetails", params: { order } });
    },
  },
  computed: {
    notOrders() {
      return this.orders.length === 0;
    },
    elementByPage() {
      return 10;
    },
    paginateOrders() {
      const firstElement = (this.page - 1) * this.elementByPage;
      const lastElement = this.page * this.elementByPage;
      return this.orders.slice(firstElement, lastElement);
    },
    length() {
      return Math.ceil(this.orders.length / this.elementByPage);
    },
  },
};
</script>

<style lang="sass">
.v-image
  transition: all .3s ease-in-out

.startup-list-card
  &:hover
    h6
      transition: color .3s
      color: var(--v-primary-base)

    .v-image
      transform: scale(1.035)
</style>
